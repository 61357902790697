import React from 'react'
import SbEditable from 'storyblok-react'
import { Tooltip as ACSTooltip } from '@system'

const Tooltip = (props) => {
  const newProps = { ...props, ...props.blok }
  return (
    <SbEditable content={props.blok}>
      <ACSTooltip {...newProps} />
    </SbEditable>
  )
}
export default Tooltip
